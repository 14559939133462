import React from 'react'
import MasterLayout from "../MasterLayout"

export default function ContactPage() {
  return (
    <MasterLayout title={"Contact page"}>
      Contact page
    </MasterLayout>
  )
}
