import React from 'react'
import MasterLayout from "./MasterLayout"

export default function Dashboard() {
  return (
    <MasterLayout title={"Dashboard"}>
      Dashboard
    </MasterLayout>
  )
}
